import { getRoutePathByItem } from "@constants";

// don't change THUMBNAILS name/type, it's used by `pages-loader` webpack loader
// see scripts/webpack/loaders/pages-loader.js
// prototype: device/name => thumbnail
const Campaign_THUMBNAILS = {
  "desktop/carousel-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAX/xAAgEAABAgUFAAAAAAAAAAAAAAACAREAAwQiQRITIXHB/8QAFgEAAwAAAAAAAAAAAAAAAAAAAAEC/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAMAwEAAhEDEQA/AJY7Om9TQssL+wA2mMkp5SCrXc9PCMiYIGTqmImqf//Z",
  "desktop/carousel-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABQT/xAAgEAACAgEDBQAAAAAAAAAAAAABAgMRABIVIjFBYXHB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAAEEh/9oADAMBAAIRAxEAPwApRFXNmB70t/cC1GXbQAaf15zNWEJFidgXjOoCuuU1/9k=",
  "desktop/carousel-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAX/xAAeEAACAQMFAAAAAAAAAAAAAAABAgADBCEREjFBUf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8ASyHaTqOJJNsyHrBWGAuT2YEsInkC/9k=",
  "desktop/bottom-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAQMEAv/EACMQAAIBAwMEAwAAAAAAAAAAAAECEQADEhMhMQRBYXFCkaH/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABgRAQEAAwAAAAAAAAAAAAAAAAARAQJB/9oADAMBAAIRAxEAPwCXpby2gRoZTyQaBr6TuCk247Fdv2omt6lYZRIB8gc1Vas27oWDK+ln7osFzeT5K3jGhCsc95UeqGX/2Q==",
  "desktop/bottom-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAMB/8QAIBAAAQQBBAMAAAAAAAAAAAAAAQACAxExBBIhQRNCUf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAMAwEAAhEDEQA/AHukLAXVgINh1bJLoHKCnnHwoATznluxw6sjKiwaCQxg2Ob6tFio1JPq4Ikf/9k=",
  "desktop/bottom-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAUC/8QAHxAAAgICAgMBAAAAAAAAAAAAAQIDEQAhEjEEE0FR/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oADAMBAAIRAxEAPwBMUrnw3kKH2AHRH2vzKjEMskkLmUcSDrVYAxGzjkrKASezjRYdgEbjXd95mtQGaVtgqay1ImigKK7wr//Z",
  "desktop/bottom-4": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAASABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EAB0QAAICAQUAAAAAAAAAAAAAAAABAjGBAxIhYZH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8Ax0m6AeyXXqAkBqTVcZAp6jdOWWBADAAAD//Z",
  "mobile/carousel-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAQMCBAX/xAAfEAACAgIBBQAAAAAAAAAAAAABEQACAxMUISMxUmP/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8AyBQ28KAdN2lAgQioDMebUSqY7P3q4DOWUexg6/MQK0D/2Q==",
  "mobile/carousel-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAASABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAmEAABAwIFAwUAAAAAAAAAAAABAAIDESEEEiJBURMUMWJxkbHw/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgEA/8QAGxEBAAEFAQAAAAAAAAAAAAAAAAECAxESMUH/2gAMAwEAAhEDEQA/AJILoC3etqq+OkoIw0+RX98LF4lbEC5pt6ljI7NlDjqAtwplsKFjiSa1Psi6qRkDVtsSim0LrK2LJuuScntmX4+kRC36VaYSei26IiE9OOP/2Q==",
  "mobile/carousel-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAwECBP/EAB8QAQABBAIDAQAAAAAAAAAAAAECABESMQMhE2GBof/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhEDEQA/ANCSdD8KkPnzsYuNt3KKYPCb35Pygn4EjAZSVdknVIRyyyGJbv1QlSwBeov/2Q==",
  "mobile/bottom-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAgMEBf/EAB8QAAICAgIDAQAAAAAAAAAAAAECAAMEESExEhNBof/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8AoJHDfCP2ZaZ9+TaLCFYqAOtREU41hsoVm7MKFbfJWAJAYyKRdR7H2j641oiJUw/HU10KpHIgf//Z",
  "mobile/bottom-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwIE/8QAIhAAAQQBAgcAAAAAAAAAAAAAAQACAxESBCETMkFRcZGx/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwDXPJw8XEjn6qVYUvNb/EAvlp1AZeFBEundLEGvOJBtNMDqCRJtdKNBt3c+kH//2Q==",
  "mobile/bottom-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAUC/8QAHxAAAgICAgMBAAAAAAAAAAAAAQIDEQAhEjEEE0FR/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oADAMBAAIRAxEAPwBMUrnw3kKH2AHRH2vzKjEMskkLmUcSDrVYAxGzjkrKASezjRYdgEbjXd95mtQGaVtgqay1ImigKK7wr//Z"
};

const getThumbnailByKey = imgPath => {
  const key = Object.keys(Campaign_THUMBNAILS).find(
    k => imgPath.indexOf(k) !== -1
  );

  return Campaign_THUMBNAILS[key] || false;
};

export default ({ imgParams, pathfinder, i18n }) => {
  const _i18n = i18n.pages.Campaign;

  const extractImage = src => {
    const re = new RegExp(`^v(\\d+)\\/${imgParams.cloudinary.path}\\/(.+)$`);

    const m = re.exec(src) || {};

    return { src: m[2], version: m[1] };
  };

  const buildItem = (item, props = {}, loading = null) => ({
    img: {
      ...extractImage(item.IMG || ""),
      thumbnail: getThumbnailByKey(item.IMG),
      fluid: true,
      width: "100%",
      height: "100%",
      title: item.TITLE,
      importance: "high",
      loading,
      cloudinary: imgParams.cloudinary,
      sizes: props.sizes,
      aspect: props.aspect || 1,
      video: Boolean(item.VIDEO)
    },
    href: getRoutePathByItem(pathfinder, item)
  });

  const filterItem = (item, defaultValue = true) =>
    "undefined" === typeof item.ENABLED ? defaultValue : Boolean(item.ENABLED);

  const buildButton = button => {
    const href = getRoutePathByItem(pathfinder, button);

    if (!button.TITLE || !button.ENABLED || !href) {
      return false;
    }

    return {
      button: {
        block: true,
        variant: button.VARIANT,
        className: "px-1",
        onClick: e => {},
        href: getRoutePathByItem(pathfinder, button)
      },
      title: button.TITLE,
      icon: {
        transform: "right-2",
        icon: "angle-right"
      }
    };
  };

  const sizes = {
    carousel: {
      mobile: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1207
        },
        aspect: 0.9149
      },
      desktop: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1424
        },
        aspect: 0.3581
      }
    },
    bottomImg: {
      mobile: {
        sizes: {
          mobilePortrait: 390,
          mobileLandscape: 473,
          tabletPortrait: 213,
          tabletLandscape: 293,
          desktop: 298
        },
        aspect: 0.959
      },
      desktop: {
        sizes: {
          mobilePortrait: 390,
          mobileLandscape: 473,
          tabletPortrait: 213,
          tabletLandscape: 293,
          desktop: 350
        },
        aspect: 0.7982
      }
    }
  };

  const header =
    _i18n.header.TITLE || _i18n.header.TEXT
      ? { title: _i18n.header.TITLE, text: _i18n.header.TEXT }
      : null;

  const images = ["carousel", "bottomImg"].reduce((carry, key) => {
    return Object.assign(carry, {
      [key]: ["mobile", "desktop"].reduce((carry, device) => {
        return Object.assign(carry, {
          [device]: _i18n[key][device]
            .filter(item => filterItem(item))
            .map((item, index) =>
              buildItem(
                item,
                sizes[key][device],
                key === "bottomImg" || !index ? "eager" : null
              )
            )
        });
      }, {})
    });
  }, {});

  const buttons = _i18n.buttons.map(buildButton).filter(Boolean);

  return {
    ...images,
    header,
    buttons
  };
};
